import React from 'react';
import { Skeleton as MuiSkeleton, styled } from '@mui/material';

export const Skeleton = () => (
  <>
    <StyledSkeleton variant="rectangular" height={72} />
    <StyledSkeleton variant="rectangular" height={72} />
    <StyledSkeleton variant="rectangular" height={72} />
  </>
);

const StyledSkeleton = styled(MuiSkeleton)`
  margin: 24px 0 0 24px;
`;
