import React from 'react';
import { createBrowserRouter, Link, Outlet, RouterProvider } from 'react-router-dom';
import { AppBar, Box, Container, CssBaseline, IconButton, Toolbar, Typography } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import { Login } from './pages/Login';
import { AuthedHome } from './pages/Home';
import { AuthedRoom } from './pages/Room/Room';
import { AuthContextProvider } from './contexts/AuthContext';

export enum Routes {
  Home = '/',
  Login = '/login',
  Room = '/rooms/:deviceGuid',
}

export function App() {
  const router = createBrowserRouter([
    {
      path: Routes.Home,
      element: (
        <>
          <CssBaseline />
          <AuthContextProvider>
            <Container component="main" maxWidth="xs" sx={{ p: 0 }}>
              <Box sx={{ flexGrow: 1 }}>
                <AppBar position="static">
                  <Toolbar>
                    <IconButton
                      component={Link}
                      to="/"
                      size="large"
                      edge="start"
                      color="inherit"
                      aria-label="menu"
                      sx={{ mr: 2 }}
                    >
                      <HomeIcon />
                    </IconButton>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                      Conny
                    </Typography>
                  </Toolbar>
                </AppBar>
              </Box>
              <Outlet />
            </Container>
          </AuthContextProvider>
        </>
      ),
      children: [
        {
          path: Routes.Home,
          element: <AuthedHome />,
        },
        {
          path: Routes.Room,
          element: <AuthedRoom />,
        },
        {
          path: Routes.Login,
          element: <Login />,
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
}
