import React, { useEffect, useState } from 'react';
import { makeRequest, Method } from '../functions/makeRequest';
import { conf } from '../config';
import { Device } from '../types/Device';
import { useNavigate } from 'react-router-dom';
import { Box, Card, CardContent, Grid, styled } from '@mui/material';
import { DeviceParameters } from '../types/DeviceParameters';
import { DeviceStatus } from '../components/DeviceStatus';
import { AuthedRoute } from '../components/AuthedRoute';
import { Loader } from '../components/Loader';
import { Skeleton } from '../components/Skeleton';

type Props = {
  token: string;
};

export function Home(props: Props) {
  const navigate = useNavigate();
  const [devices, setDevices] = useState<Array<Device> | undefined>();

  useEffect(() => {
    retrieveDevices(props.token)
      .then((result) => setDevices(result))
      .catch(console.error);
  }, []);

  return (
    <>
      <Loader loading={!devices} />
      <Box component="div" sx={{ mt: 2, pl: 2, pr: 2 }}>
        <Grid container direction="column" spacing={3}>
          {devices ? (
            devices.map((device, index) => (
              <Grid item key={index} xs={12}>
                <StyledCard onClick={() => navigate(`rooms/${device.guid}`)}>
                  <Grid container justifyContent="space-between" alignItems="center">
                    <CardContent>{device.name}</CardContent>
                    <CardContent>
                      <DeviceStatus deviceParameters={device.parameters} />
                    </CardContent>
                  </Grid>
                </StyledCard>
              </Grid>
            ))
          ) : (
            <Skeleton />
          )}
        </Grid>
      </Box>
    </>
  );
}

const StyledCard = styled(Card)`
  &:hover {
    cursor: pointer;
  }
`;

async function retrieveDevices(token: string): Promise<Array<Device>> {
  const config = conf();

  return makeRequest({
    panasonicPath: config.panasonicGroupUrl,
    panasonicMethod: Method.GET,
    panasonicToken: token,
  }).then(mapDevices);
}

function mapDevices(response: Response): Array<Device> {
  return response.body.result.groupList.flatMap((group) =>
    group.deviceList.map((device) => ({
      guid: device.deviceGuid,
      name: device.deviceName,
      parameters: device.parameters,
    }))
  );
}

type Response = {
  url: string;
  ok: boolean;
  status: number;
  body: {
    result: {
      groupList: Array<{
        deviceList: Array<{
          deviceGuid: string;
          deviceName: string;
          parameters: DeviceParameters;
        }>;
      }>;
    };
  };
};

export const AuthedHome = AuthedRoute(Home);
