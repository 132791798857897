import React, { createContext, ReactNode, useState } from 'react';

export const AuthContext = createContext<{ token: string | null; onToken: (token: string) => void }>({
  token: null,
  onToken: () => {},
});

type Props = {
  children?: ReactNode;
};

export function AuthContextProvider(props: Props) {
  const tokenFromStorage = localStorage.getItem('token');
  const [token, setToken] = useState<string | null>(tokenFromStorage);

  const onToken = (token: string) => {
    localStorage.setItem('token', token);
    setToken(token);
  };

  return <AuthContext.Provider value={{ token, onToken }}>{props.children}</AuthContext.Provider>;
}
