import React from 'react';
import { LinearProgress, styled } from '@mui/material';

type Props = {
  loading: boolean;
};

export const Loader = (props: Props) => {
  return <StyledLinearProgress loading={props.loading} />;
};

const StyledLinearProgress = styled(LinearProgress)<{ loading: boolean }>`
  visibility: ${({ loading }) => (loading ? 'visible' : 'hidden')};
`;
