import React, { FormEvent, useContext, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { Alert, AlertTitle, Box, Button, TextField } from '@mui/material';
import { makeRequest, Method } from '../functions/makeRequest';
import { conf } from '../config';
import { AuthContext } from '../contexts/AuthContext';
import { Routes } from '../App';
import { handleError } from '../functions/handleError';
import { Loader } from '../components/Loader';

export function Login() {
  const authContext = useContext(AuthContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const [loggedIn, setLoggedIn] = useState<boolean>(false);

  const loginId = React.useRef<HTMLInputElement>();
  const password = React.useRef<HTMLInputElement>();

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    if (loginId.current?.value && password.current?.value) {
      setLoading(true);
      setError(undefined);
      try {
        const token = await login(loginId.current.value, password.current.value);
        authContext.onToken(token);
        setLoggedIn(true);
      } catch (error) {
        setError(handleError(error));
      } finally {
        setLoading(false);
      }
    }
  };

  if (loggedIn) {
    return <Navigate to={Routes.Home} />;
  }

  return (
    <>
      <Loader loading={loading} />
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 2, pl: 2, pr: 2 }}>
        <TextField
          inputRef={loginId}
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
        />
        <TextField
          inputRef={password}
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
        />
        <Button type="submit" disabled={loading} fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
          Sign In
        </Button>
        {error && (
          <Alert severity="error">
            <AlertTitle>There was an error</AlertTitle>
            <pre>{error}</pre>
          </Alert>
        )}
      </Box>
    </>
  );
}

async function login(loginId: string, password: string): Promise<string> {
  const config = conf();

  const result = await makeRequest({
    panasonicPath: config.panasonicLoginUrl,
    panasonicMethod: Method.POST,
    panasonicBody: {
      language: 0,
      loginId,
      password,
    },
  });
  if (result.ok) {
    return result.body.result.uToken;
  }

  throw new Error(JSON.stringify(result, null, 2));
}
