import React from 'react';
import { Chip } from '@mui/material';

type Props = {
  temperatureSet: number;
};

export function TemperatureChip(props: Props) {
  return <Chip label={props.temperatureSet} />
}
