import React, { FormEvent, useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  Collapse,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Slider,
  Snackbar,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ThermostatAutoIcon from '@mui/icons-material/ThermostatAuto';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import WaterDropIcon from '@mui/icons-material/WaterDrop';
import AirIcon from '@mui/icons-material/Air';
import { useParams } from 'react-router-dom';
import { conf } from '../../config';
import { makeRequest, Method } from '../../functions/makeRequest';
import { EcoMode, FanSpeed, OperationMode, Power } from '../../types/panasonicStates';
import { Device } from '../../types/Device';
import { DeviceParameters } from '../../types/DeviceParameters';
import { AuthedRoute } from '../../components/AuthedRoute';
import { Loader } from '../../components/Loader';
import { Skeleton } from '../../components/Skeleton';

type Props = {
  token: string;
};

export function Room(props: Props) {
  const { deviceGuid } = useParams();
  const [device, setDevice] = useState<Device | undefined>();
  const [deviceUpdate, setDeviceUpdate] = useState<Partial<DeviceParameters>>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [showSaveResult, setShowSaveResult] = useState<boolean>(false);

  useEffect(() => {
    retrieveDevice(props.token, deviceGuid!).then(setDevice);
  }, []);

  if (!device) {
    return (
      <>
        <Loader loading={true} />
        <Box component="div" sx={{ mt: 2, pl: 2, pr: 2 }}>
          <Grid container direction="column" spacing={3}>
            <Skeleton />
          </Grid>
        </Box>
      </>
    );
  }

  const getDeviceParameters = (): DeviceParameters => {
    return {
      ...device.parameters,
      ...deviceUpdate,
    };
  };

  const hasUnsavedChanges = (): boolean => {
    return Object.keys(deviceUpdate).length > 0;
  };

  const updateOperate = (checked: boolean) => {
    const newStatus = checked ? Power.On : Power.Off;
    if (device.parameters.operate === newStatus) {
      const { operate, ...rest } = deviceUpdate;
      setDeviceUpdate(rest);
    } else {
      setDeviceUpdate({
        ...deviceUpdate,
        operate: newStatus,
      });
    }
  };

  const updateOperationMode = (event: React.MouseEvent<HTMLElement>, newOperationMode: OperationMode | null) => {
    if (newOperationMode !== null) {
      if (device.parameters.operationMode === newOperationMode) {
        const { operationMode, ...rest } = deviceUpdate;
        setDeviceUpdate(rest);
      } else {
        setDeviceUpdate({
          ...deviceUpdate,
          operationMode: newOperationMode,
        });
      }
    }
  };

  const updateTemperature = (event: Event, newValue: number | number[]) => {
    if (device.parameters.temperatureSet === newValue) {
      const { temperatureSet, ...rest } = deviceUpdate;
      setDeviceUpdate(rest);
    } else {
      setDeviceUpdate({
        ...deviceUpdate,
        temperatureSet: newValue as number,
      });
    }
  };

  const getFan = (): number => {
    const ecoMode = getDeviceParameters().ecoMode;
    const fanSpeed = getDeviceParameters().fanSpeed;

    if (ecoMode === EcoMode.Powerful) {
      return 7;
    }
    if (ecoMode === EcoMode.Quiet) {
      return 0;
    }
    if (fanSpeed === FanSpeed.Auto) {
      return 6;
    }
    return fanSpeed;
  };

  const updateFan = (event: Event, newValue: number | number[]) => {
    let newEcoMode = EcoMode.Auto;
    let newFanSpeed = FanSpeed.Auto;

    if (newValue === 7) {
      newEcoMode = EcoMode.Powerful;
      newFanSpeed = FanSpeed.Auto;
    }
    if (newValue === 0) {
      newEcoMode = EcoMode.Quiet;
      newFanSpeed = FanSpeed.Auto;
    }
    if (newValue === 6) {
      newEcoMode = EcoMode.Auto;
      newFanSpeed = FanSpeed.Auto;
    }
    if (newValue >= 1 && newValue <= 5) {
      newEcoMode = EcoMode.Auto;
      newFanSpeed = newValue as FanSpeed;
    }

    if (device.parameters.ecoMode === newEcoMode && device.parameters.fanSpeed === newFanSpeed) {
      const { ecoMode, fanSpeed, ...rest } = deviceUpdate;
      setDeviceUpdate(rest);
    } else {
      setDeviceUpdate({
        ...deviceUpdate,
        ecoMode: newEcoMode,
        fanSpeed: newFanSpeed,
      });
    }
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    if (deviceUpdate) {
      setLoading(true);
      setShowSaveResult(false);
      await updateDevice(props.token, deviceGuid!, deviceUpdate);
      const updatedDevice = await retrieveDevice(props.token, deviceGuid!);
      setDevice(updatedDevice);
      setDeviceUpdate({});
      setLoading(false);
      setShowSaveResult(true);
    }
  };

  return (
    <>
      <Loader loading={loading} />
      <Box component="div" sx={{ mt: 2, pl: 2, pr: 2 }}>
        <Snackbar
          open={showSaveResult}
          autoHideDuration={3000}
          onClose={() => setShowSaveResult(false)}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert onClose={() => setShowSaveResult(false)} severity="success">
            Saved
          </Alert>
        </Snackbar>
        <Box component="form" onSubmit={handleSubmit}>
          <FormGroup>
            <Paper sx={{ mb: 2, p: 2 }}>
              <Typography variant="subtitle2" gutterBottom>
                Power
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={getDeviceParameters().operate === Power.On}
                      onChange={(event) => updateOperate(event.target.checked)}
                      disabled={loading}
                      color="success"
                    />
                  }
                  label={getDeviceParameters().operate === Power.On ? 'On' : 'Off'}
                />
              </Box>
            </Paper>
            {getDeviceParameters().operate === Power.On && (
              <>
                <Paper sx={{ mb: 2, p: 2 }}>
                  <Typography variant="subtitle2" gutterBottom>
                    Mode
                  </Typography>
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <ToggleButtonGroup
                      value={getDeviceParameters().operationMode}
                      onChange={updateOperationMode}
                      disabled={loading}
                      exclusive
                      color="primary"
                    >
                      <ToggleButton value={OperationMode.Auto}>
                        <ThermostatAutoIcon />
                      </ToggleButton>
                      <ToggleButton value={OperationMode.Heat}>
                        <LocalFireDepartmentIcon />
                      </ToggleButton>
                      <ToggleButton value={OperationMode.Cool}>
                        <AcUnitIcon />
                      </ToggleButton>
                      <ToggleButton value={OperationMode.Dry}>
                        <WaterDropIcon />
                      </ToggleButton>
                      <ToggleButton value={OperationMode.Fan}>
                        <AirIcon />
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Box>
                </Paper>
                <Collapse orientation="vertical" in={getDeviceParameters().operationMode !== OperationMode.Fan}>
                  <Paper sx={{ mb: 2, p: 2 }}>
                    <Typography variant="subtitle2" gutterBottom>
                      Temperature
                    </Typography>
                    <Box sx={{ pl: 3, pr: 3 }}>
                      <Slider
                        value={getDeviceParameters().temperatureSet}
                        onChange={updateTemperature}
                        disabled={loading}
                        step={1}
                        min={16}
                        max={30}
                        marks={temperatureMarks}
                        valueLabelDisplay="auto"
                      />
                    </Box>
                  </Paper>
                </Collapse>
                <Paper sx={{ mb: 2, p: 2 }}>
                  <Typography variant="subtitle2" gutterBottom>
                    Fan
                  </Typography>
                  <Box sx={{ pl: 3, pr: 3 }}>
                    <Slider
                      value={getFan()}
                      onChange={updateFan}
                      disabled={loading}
                      step={1}
                      min={0}
                      max={7}
                      marks={fanMarks}
                      valueLabelDisplay="auto"
                      valueLabelFormat={(value: number) => fanMarks[value].label}
                    />
                  </Box>
                </Paper>
              </>
            )}
            <Button
              type="submit"
              disabled={loading || !hasUnsavedChanges()}
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Save
            </Button>
          </FormGroup>
        </Box>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography>This is the device</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <pre>{JSON.stringify(device, null, 2)}</pre>
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  );
}

export const AuthedRoom = AuthedRoute(Room);

async function retrieveDevice(token: string, deviceGuid: string): Promise<Device> {
  const config = conf();

  return makeRequest({
    panasonicPath: config.panasonicDeviceUrl(deviceGuid),
    panasonicMethod: Method.GET,
    panasonicToken: token,
  }).then((response) => response.body.result);
}

async function updateDevice(token: string, deviceGuid: string, parameters: Partial<DeviceParameters>): Promise<void> {
  const config = conf();

  await makeRequest({
    panasonicPath: config.panasonicDeviceControlUrl,
    panasonicMethod: Method.POST,
    panasonicToken: token,
    panasonicBody: {
      deviceGuid,
      parameters,
    },
  });
}

const temperatureMarks = [
  {
    value: 17,
    label: '17°C',
  },
  {
    value: 20,
    label: '20°C',
  },
  {
    value: 21,
  },
  {
    value: 22,
    label: '22°C',
  },
  {
    value: 23,
  },
  {
    value: 24,
    label: '24°C',
  },
  {
    value: 29,
    label: '29°C',
  },
];

const fanMarks = [
  {
    value: 0,
    label: 'Quiet',
  },
  {
    value: 1,
    label: '1',
  },
  {
    value: 2,
    label: '2',
  },
  {
    value: 3,
    label: '3',
  },
  {
    value: 4,
    label: '4',
  },
  {
    value: 5,
    label: '5',
  },
  {
    value: 6,
    label: 'Auto',
  },
  {
    value: 7,
    label: 'Max',
  },
];
