import { conf } from '../config';

export async function makeRequest(body: Body) {
  const config = conf();

  const headers = new Headers({
    'Content-Type': 'application/json',
    Accept: 'application/json',
  });

  const request = new Request(config.apiUrl, {
    method: Method.POST,
    headers,
    body: JSON.stringify(body),
  });

  const response: Response = await fetch(request);
  return {
    url: response.url,
    ok: response.ok,
    status: response.status,
    body: await response.json(),
  };
}

export enum Method {
  GET = 'GET',
  POST = 'POST',
}

export type Body = {
  panasonicPath: string;
  panasonicMethod: Method;
  panasonicToken?: string;
  panasonicBody?: any;
};
