export type Config = {
  apiUrl: URL;
  panasonicLoginUrl: string;
  panasonicGroupUrl: string;
  panasonicDeviceUrl: (guid: string) => string;
  panasonicDeviceNowUrl: (guid: string) => string;
  panasonicDeviceControlUrl: string;
  panasonicDeviceHistoryUrl: string;
};

export function conf(): Config {
  switch (getNodeEnv()) {
    case 'development':
      return {
        apiUrl: new URL('http://localhost:3000/api'),
        panasonicLoginUrl: '/auth/login',
        panasonicGroupUrl: '/device/group',
        panasonicDeviceUrl: (guid: string) => `/deviceStatus/${guid}`,
        panasonicDeviceNowUrl: (guid: string) => `/deviceStatus/now/${guid}`,
        panasonicDeviceControlUrl: '/deviceStatus/control',
        panasonicDeviceHistoryUrl: '/deviceHistoryData/',
      };
    case 'production':
      return {
        apiUrl: new URL('https://conny-green.vercel.app/api'),
        panasonicLoginUrl: '/auth/login',
        panasonicGroupUrl: '/device/group',
        panasonicDeviceUrl: (guid: string) => `/deviceStatus/${guid}`,
        panasonicDeviceNowUrl: (guid: string) => `/deviceStatus/now/${guid}`,
        panasonicDeviceControlUrl: '/deviceStatus/control',
        panasonicDeviceHistoryUrl: '/deviceHistoryData/',
      };
    default:
      throw new Error(`No config for NODE_ENV ${getNodeEnv()}`);
  }
}

function getNodeEnv(): string {
  const value = process.env.NODE_ENV;
  if (!value) {
    throw new Error(`No process.env.NODE_ENV specified`);
  }
  return value;
}
