import React from 'react';
import { DeviceParameters } from '../types/DeviceParameters';
import { PowerChip } from './PowerChip';
import { Power } from '../types/panasonicStates';
import { TemperatureChip } from './TemperatureChip';
import { Grid } from '@mui/material';

type Props = {
  deviceParameters: DeviceParameters;
};

export function DeviceStatus(props: Props) {
  return props.deviceParameters.operate === Power.On ? (
    <Grid container spacing={1}>
      <Grid item>
        <TemperatureChip temperatureSet={props.deviceParameters.temperatureSet} />
      </Grid>
      <Grid item>
        <PowerChip state={props.deviceParameters.operate} />
      </Grid>
    </Grid>
  ) : (
    <PowerChip state={props.deviceParameters.operate} />
  );
}
