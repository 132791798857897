import React from 'react';
import { Chip } from '@mui/material';
import { Power } from '../types/panasonicStates';

type Props = {
  state: Power;
};

export function PowerChip(props: Props) {
  switch (props.state) {
    case Power.On:
      return <Chip label="On" color="success" />;
    case Power.Off:
      return <Chip label="Off" variant="outlined" />;
  }
}
