import React, { FC, useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { Navigate } from 'react-router-dom';
import { Routes } from '../App';

type InjectedProps = {
  token: string;
};

export function AuthedRoute(Component: FC<InjectedProps>) {
  return function (passThroughProps: object) {
    const { token } = useContext(AuthContext);
    return token ? <Component token={token} {...passThroughProps} /> : <Navigate to={Routes.Login} />;
  };
}
